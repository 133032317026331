import type { SvelteAction } from '@tickrr/lib/types';

export const focusOnMount: SvelteAction<{ delay?: number; enabled?: boolean } | undefined> = (
	node,
	opts
) => {
	const delay = opts?.delay ?? 0;
	const enabled = opts?.enabled ?? true;
	if (!enabled) return;
	setTimeout(() => node.focus(), delay);
};
